import React from 'react';

function Credits() {
    return (
        <div className="version">
            <p>Version 1.3.2 09082567.1153</p>
        </div>
    );
}

export default Credits;