import React from 'react';
import { Card, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

const { Text } = Typography;

function AnsweredCard(props) {
    const { startTime, answerInfo} = props;
    //
    // const answerInfo = {
    //     'Player 1': '8797465435',
    //     'Player 2': '1610',
    //     'Player 3': '1500',
    //
    //
    // }

    // Filter out players who did not submit an answer
    const filteredAnswerInfo = Object.entries(answerInfo).filter(([player, submittedTimeStr]) => submittedTimeStr !== '');

    // Convert submittedTimeStr to timeTaken before sorting
    const convertedAnswerInfo = filteredAnswerInfo.map(([player, submittedTimeStr]) => {
        const submittedTime = parseInt(submittedTimeStr);
        const timeTaken = (typeof submittedTime === 'number' && typeof parseInt(startTime) === 'number')
            ? (submittedTime - parseInt(startTime)) / 1000
            : 'Time not available';
        return { player, timeTaken, submittedTimeStr };
    });

    // Sort the convertedAnswerInfo array based on timeTaken in ascending order
    const sortedAnswerInfo = convertedAnswerInfo.sort((a, b) => a.timeTaken - b.timeTaken);

    return (
        <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 40, width: 9999999 }}>
            {sortedAnswerInfo.map(({ player, timeTaken }) => (
                <Card
                    key={player}
                    style={{ width: '200px', textAlign: 'center', margin: 10 }}
                >
                    <div style={{ margin: 0 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
                            <audio autoPlay src="/Wink.mp3" />
                            <UserOutlined style={{ marginRight: 8, fontSize: 20 }} />
                            <Text style={{ fontSize: 20, margin: 5 }}>{player}</Text>
                        </div>
                        <Text style={{ marginTop: 0 }}>{timeTaken} seconds</Text>
                    </div>
                </Card>
            ))}
        </div>
    );
}

export default AnsweredCard;