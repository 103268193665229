import './App.css';
import NavBar from "./components/navbar/navbar";
import NotReady from "./components/body/notready";
import GameScreen from "./components/gamescreen/runninggame";
// import SettingBox from "./components/settings/settingbox";
import Credits from "./components/body/credits";
import React, {useEffect, useState} from "react";
import {Button, Image, Input, Modal, notification} from "antd";
import useWebSocket, {ReadyState} from "react-use-websocket";
import {CodeTwoTone, FrownOutlined, NotificationTwoTone, SmileFilled} from "@ant-design/icons";
import Version from "./components/body/version";

function App() {
    const [socketUrl, setSocketUrl] = useState(`ws://iq180.bhira.me:8888`);
    // const [messageHistory, setMessageHistory] = useState([]);
    const [currentRound, setCurrentRound] = useState(0);
    const [endRound, setEndRound] = useState(0);
    const [gameStarted, setGameStarted] = useState(false); // New state variable
    const [gameNotFound, setGameNotFound] = useState(false);
    const [playerList, setPlayerList] = useState(null);
    const [gamePin, setGamePin] = useState('e');
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [puzzleNumber, setPuzzleNumber] = useState([]);
    const [targetNumber, setTargetNumber] = useState(0);
    const [answerInfo, setAnswerInfo] = useState({});
    const [showScoreboard, setShowScoreboard] = useState(false);
    const [scoreBoardData, setScoreBoardData] = useState({bhira: [1, 2, 3]});
    const [answerDetails, setAnswerDetails] = useState(null);
    const [solutionCount, setSolutionCount] = useState(0)
    const [solutionImage, setSolutionImage] = useState(null)
    const [canSolved, setCanSolved] = useState("false")
    const [answerImage, setAnswerImage] = useState("")
    const {sendMessage, lastMessage, readyState} = useWebSocket(socketUrl, {share: true, shouldReconnect: () => true});
    const [modalName, setModalName] = useState("");
    const [api, contextHolder] = notification.useNotification();

    let answerLetImage = "";

    useEffect(() => {
        console.log("Connection status chaged to: ", readyState);
        if (readyState === ReadyState.OPEN) {
            console.log('Connection established. Ready to send messages.');
        }

    }, [readyState])
    useEffect(() => {
        if (lastMessage !== null) {
            const messageData = lastMessage.data;
            if (messageData === "Game not found") {
                setGameNotFound(true);
                setGameStarted(false);
            } else if (gameStarted === false) {
                console.log('lastMessage:', messageData)
                if (messageData === "Game found") {
                    // Set gameStarted to true
                    setGameStarted(true);
                    setGameNotFound(false);
                    subscribeAll();
                } else {
                    // Show AntD Alert
                    setGameNotFound(true);
                    console.log('lastMessage:', messageData);
                }
            } else {
                // console.log('lastMessage:', lastMessage);
                const message = JSON.parse(messageData);
                console.log('Message:', message);
                if (message.current_round_number) {
                    setCurrentRound(parseInt(message.current_round_number));
                } else if (message.end_round_number) {
                    setEndRound(parseInt(message.end_round_number));
                } else if (message.player_list) {
                    setPlayerList(message.player_list);
                } else if (message.puzzle_number) {
                    console.log('Puzzle number:', message.puzzle_number)
                    setPuzzleNumber(message.puzzle_number);
                    setTargetNumber(message.target_number);
                    setStartTime(message.start_time);
                    setEndTime(message.end_time);
                } else if (message.player_answer_time) {
                    setAnswerInfo(message.player_answer_time)
                } else if (message.show_scoreboard) {
                    setShowScoreboard(message.show_scoreboard);
                } else if (message.image) {
                    console.log(message.image)
                    // setAnswerImage(message.image);
                    // answerLetImage = message.image;
                    showModal(message.image,modalName);
                } else if (message.answer_detail) {
                    setAnswerDetails(message.answer_detail);
                } else if (message.solution_count) {
                    setSolutionCount(message.solution_count);
                    setSolutionImage(message.example_solution);
                    setCanSolved(message.can_solved);
                } else if (message.scoreboard) {
                    console.log("Scoreboard data: ", message.scoreboard);
                    console.log(typeof message)
                    setScoreBoardData(message);

                }
            }
        }
    }, [lastMessage]);
    const showModal = async (answerImage,playerName) => {
        Modal.info({
            title: `Answer Image - ${playerName} `,
            width: '95vh',
            content: (
                <>Puzzle Number - {puzzleNumber.join(' ')} -> {targetNumber}
                <Image
                    alt={"Answer - " + playerName}
                    style={{width: '80vh', maxHeight: '70vh'}}
                    src={answerImage}
                    fallback={""}
                />
                </>
            ),
            onOk() {
                // setAnswerImage(null);
                clearImage()
            },
        });
    };


    // UNRELATED FUNCTION
    function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // BEFORE START FUNCTION
    function joinGame(gamePin) {
        sendMessage(gamePin);
        console.log('Joining game with pin:', gamePin);
    }
    const openNoGameNotification = () => {
        api.open({
            message: 'Error ! Game Not Found',
            description:
                'Please check or retry game pin.',
            icon: (
                <FrownOutlined
                    style={{
                        color: '#fa8282',
                    }}
                />
            ),
        });
    };

    const openGameFoundNotification = () => {
        api.open({
            message: 'Entered the Game!',
            description:
                'You have successfully attend the game.',
            icon: (
                <SmileFilled
                    style={{
                        color: '#29d84d',
                    }}
                />
            ),
        });

    }

    useEffect(() => {
        if (gameNotFound) {
            openNoGameNotification()
            setGameNotFound(false)
        }
        if (gameStarted) {
            openGameFoundNotification()
        }
    }, [gameNotFound,gameStarted])

    function subscribeAll() {
        const methods = [
            "get_current_round_number",
            "get_end_round_number",
            "get_round_info",
            "get_player_list",
            "get_scoreboard",
            "is_game_finished",
            "is_show_scoreboard"
        ];

        methods.forEach((method, index) => {
            sendMessage(JSON.stringify({"method": method}));
            console.log("WE are sending message: ", method);
            sleep(100);
        });
    }

    // ------------------------------

    // DURING ROUND CHANGE
    useEffect(() => {
        console.log("Sheet")
        if (gameStarted) {
            const methods = [
                "get_player_answer_info",
                "get_answer_of_round",
                "get_answer_detail"
            ]
            setEndTime(0)
            methods.forEach((method, index) => {
                setTimeout(() => {
                    sendMessage(JSON.stringify({"method": method, "round_number": currentRound}));
                    console.log("WE are sending message: ", method);
                }, index * 100);
                subscribeAll();
            });
            setAnswerDetails(null);
            setAnswerInfo({});
            setPuzzleNumber([]);
            setTargetNumber(0);

        }
    }, [currentRound, gameStarted, sendMessage]);

    //-------- Other functions
    function clearImage() {
        setAnswerImage("");
    }


    return (
        <div className="App" style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            maxHeight: '100%',
            minWidth: '100hv',
            maxWidth: '100%',
            backgroundImage: 'url("/assets/bg_noplain2.png")',
            backgroundSize: 'cover'
        }}>
            {contextHolder}
            {/*{gameNotFound && (*/}
            {/*    <Alert*/}
            {/*        message="Error Text"*/}
            {/*        description="Game not found. Please try again later."*/}
            {/*        type="error"*/}
            {/*        closable*/}
            {/*        onClose={() => setGameNotFound(false)}*/}
            {/*    />*/}
            {/*)}*/}
            {!gameStarted ? (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    height: '100vh',
                    width: '100%',
                    paddingTop: '5%',

                }}>
                    {/*<h1 style={{fontSize: 'xxx-large'}}>Welcome to IQ180 Display!</h1>*/}
                    <img src={"/assets/welcome.png"} alt="logo" style={{width: '50%', height: 'auto'}}/>
                    <Input size="large" prefix={<NotificationTwoTone/>} placeholder="End Point"
                           defaultValue={socketUrl} onChange={(event) => setSocketUrl(event.target.value)}
                           style={{width: '30%', textAlign: 'center'}}/>
                    <Input.Password size="large" prefix={<CodeTwoTone/>} placeholder="Game Pin"
                           onChange={(event) => setGamePin(event.target.value)} onPressEnter={() => joinGame(gamePin)}
                           style={{width: 'fit-content', fontSize: 'x-large', textAlign: 'center'}}/>
                    <Button style={{
                        borderColor: 'gray',
                        borderWidth: '1px',
                        width: "fit-content",
                        height: 'fit-content',
                        fontSize: 'xx-larger',
                    }}
                            onClick={() => joinGame(gamePin)}
                    >
                        Join Game Display!
                    </Button><Credits/>
                    <Version/>
                </div>
            ) : (
                <>
                    {/*<SettingBox style={{flexGrow: 1}}/>*/}
                    {currentRound === 0 &&
                        <NotReady playerList={playerList}/>}
                    {currentRound !== 0 && <><NavBar style currentRound={currentRound} endRound={endRound}/>
                        <GameScreen currentRound={currentRound} startTime={startTime} endTime={endTime}
                                    puzzleNumber={puzzleNumber} targetNumber={targetNumber} answerInfo={answerInfo}
                                    showScoreboard={showScoreboard} sendMessage={sendMessage}
                                    scoreBoardData={scoreBoardData}
                                    answerLetImage={answerLetImage} answerImage={answerImage} canSolved={canSolved}
                                    soluionImage={solutionImage} solutionCount={solutionCount}
                                    answerDetails={answerDetails} setAnswerImage={setAnswerImage} setModalName={setModalName}
                                    setEndTime={setEndTime}/>
                    </>}
                    <Credits/>
                </>
            )}
        </div>
    );
}

export default App;