import React, {useEffect, useState} from "react";
import AnswerBoard from "../table/pauseboard";
import ScoreBoard from "../table/new_scoreboard";
import ProgressBar from "../timer/timerbar";
import AnsweredCard from "./runningbar";
import {Button, Flex, Popconfirm} from "antd";
import {ScissorOutlined} from "@ant-design/icons";

function GameScreen(props) {
    const {
        currentRound,
        startTime,
        endTime,
        puzzleNumber,
        targetNumber,
        answerInfo,
        showScoreboard,
        sendMessage,
        answerImage,
        answerDetails,
        solutionCount,
        soluionImage,
        canSolved,
        setAnswerImage,
        scoreBoardData,
        setModalName,
        setEndTime
    } = props;
    const [showRandomEffect, setShowRandomEffect] = useState(true);
    let answerLetImage = props.answerImage;
    const [currentTime, setCurrentTime] = useState(new Date().getTime());
    const [displayNumbers, setDisplayNumbers] = useState([]);
    useEffect(() => {
        setShowRandomEffect(true);
        let tempNumbers = Array.from({length: puzzleNumber.length}, () => Math.floor(Math.random() * 10));
        setDisplayNumbers(tempNumbers);

        const intervals = puzzleNumber.map((num, index) => {
            const intervalId = setInterval(() => {
                tempNumbers[index] = Math.floor(Math.random() * 10); // Keep randomizing
                setDisplayNumbers([...tempNumbers]);
            }, 100); // Randomize every 100ms, adjust as needed

            // Delay before showing the actual number to simulate randomness
            const revealDelay = index * 250 + 1500; // Adjust 1000 (initial delay) as needed
            setTimeout(() => {
                clearInterval(intervalId); // Stop randomizing
                tempNumbers[index] = num; // Set to actual number
                setDisplayNumbers([...tempNumbers]);

                // If it's the last number, stop the random effect after it's revealed
                if (index === puzzleNumber.length - 1) {
                    setTimeout(() => {
                        setShowRandomEffect(false);
                    }, 1000);
                }
            }, revealDelay);

            return intervalId;
        });

        // Cleanup: Clear all intervals when component unmounts or puzzleNumber changes
        return () => intervals.forEach(clearInterval);

    }, [puzzleNumber.length]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date().getTime());
            // if (currentTime > endTime) {
            //     // checkShowScoreboard();
            // }
            // console.log(currentTime, "Current Time");
            // console.log(endTime, "End Time");
            // console.log(startTime, "Start Time");
            // console.log(typeof startTime)
        }, 500);
        return () => clearInterval(intervalId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (currentTime-1000 > endTime && endTime !== 0) {
        // console.log(showScoreboard, "Show Scoreboard");
        if (showScoreboard === 'true') {
            // in case of showing scoreboard, return scoreboard
            return (
                <>
                    <Flex justify={"center"}>
                        <h1 style={
                            {
                                textAlign: 'center',
                                marginTop: '20px',
                                marginBottom: '20px',
                                fontSize: '40px',
                                fontWeight: 'bold',
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                padding: '10px',
                                width: '20%',
                                border: '3px solid black'
                            }
                        }>Scoreboard</h1>
                    </Flex>
                    <ScoreBoard rawData={scoreBoardData} answerInfo={answerInfo}/>
                </>
            );
        } else {
            // in case of not showing scoreboard, return answer board
            // console.log(answerInfo, "Answer Info")
            return (
                <div className="columns-container">
                    <div className="column-left" style={{width: '70%', marginLeft: '20px', marginTop: '10px'}}>
                        <AnswerBoard roundNumber={currentRound} answerInfo={answerInfo} answerDetails={answerDetails}
                                     sendMessage={sendMessage} answerImage={answerImage} solutionCount={solutionCount}
                                     answerLetImage={answerLetImage} solutionImage={soluionImage} canSolved={canSolved}
                                     setAnswerImage={setAnswerImage} setModalName={setModalName}/>
                    </div>
                    <div className="column-right" style={{width: '30%'}}>
                        <div
                            className="info-box"
                            style={{
                                border: '3px solid black',
                                padding: '10px',
                                width: '50%',
                                height: '60vh',
                                margin: '30px',
                                backgroundColor: 'white',
                                borderRadius: '10px', // Making the card have a radius
                            }}
                        >
                            <div className="info-item">
                                <h2>Puzzle Number</h2>
                                <p className={'pt-number'}>{puzzleNumber.join('     ')}</p>
                            </div>
                            <div className="info-item">
                                <h2>Target Number</h2>
                                {targetNumber !== null && targetNumber !== "null" && targetNumber !== 0 ? (
                                    <p className={'pt-number'}>{targetNumber}</p>
                                ) : (
                                    <p className={'pt-number'}>- - -</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    } else {
        // in case of showing game screen, return game screen
        return (
            <>
                <div
                    className="info-box"
                    style={{
                        border: '3px solid black',
                        padding: '10px',
                        height: '60vh',
                        marginTop: '30px',
                        marginLeft: '50px',
                        marginRight: '50px',
                        backgroundColor: 'white',
                        borderRadius: '10px', // Making the card have a radius
                    }}
                >
                    <div className="info-item">
                        <h2 style={{lineHeight: 0}}>Puzzle Number</h2>
                        {puzzleNumber.length === 0 ? (
                            <p className={'pt-number'} style={{color: "#e1e1e1"}}>- - - - -</p>
                        ) : (
                            showRandomEffect ? (
                                <p className={'pt-number'}
                                   style={{
                                       fontWeight: 'lighter',
                                       color: '#869988'
                                   }}>{displayNumbers.join('       ')}</p>

                            ) : (<p className={'pt-number'}
                                    style={{fontWeight: 'bold', color: '#20ad52'}}>{puzzleNumber.join('       ')}</p>)
                        )
                        }
                    </div>
                    {/*<FloatButton type="primary" style={{position: "absolute", bottom: "0", left: "0",zIndex : 4}} icon={<ScissorOutlined />} tooltip={<div>Show PauseBoard</div>} onClick={setEndTime(-1)} />*/}
                    <ProgressBar start={startTime} end={endTime}/>
                    {endTime - currentTime <= 5500 && endTime !== null && endTime !== 0 && endTime !== "null" ? (
                        <audio src="/bellring.mp3" autoPlay/>
                    ) : null}
                    <div className="info-item">
                        <h2 style={{lineHeight: 0}}>Target Number</h2>
                        {targetNumber !== null && targetNumber !== "null" && targetNumber !== 0 ? (
                            <>
                                <p className={'pt-number'}>{targetNumber}</p>
                                <audio src="/startbell.mp3" autoPlay preload/>
                            </>
                        ) : (
                            <p className={'pt-number'} style={{color: "#e1e1e1"}}>888</p>
                        )
                        }
                    </div>
                    <Popconfirm title={"Are you sure to show Pause/Score board?"} onConfirm={() => setEndTime(-1)}>
                        <Button style={{position: "fixed",top:10,right:10}} type="dashed" shape="circle" icon={<ScissorOutlined/>}/></Popconfirm>
                </div>
                <AnsweredCard startTime={startTime} answerInfo={answerInfo}/>
            </>
        );
    }
}

export default GameScreen;