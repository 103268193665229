import React from 'react';
import './navbar.css';

const Navbar = (props) => {
    const {currentRound, endRound} = props;

    return (
        <div className="navbar" style={{margin: 0, height: "0px"}}>
            <div className="navbar-center">
                <p style={{fontSize: "32px",}}>Round {currentRound}/{endRound}</p>
            </div>
        </div>
    );
};

export default Navbar;
