import React, {useEffect, useState} from 'react';
import {Card, Col, Row, Typography} from 'antd';
import {UserOutlined} from '@ant-design/icons';

const { Text } = Typography;

function NotReady(props) {
    const {playerList} = props;
    useEffect(() => {
        console.log('Player list:', playerList);
    }, []);
    const gridStyle = {
        width: 'auto',
        textAlign: 'left',
        hoverable: true,
        margin: 10,
        padding: 0,
        borderColor: 'black',
    };
    return (
        <>
            <div className="rectangle">
                <h1 style={{fontSize: "40px", margin: "0px"}}>CUD IQ180 Game</h1>
            </div>
            <Card title={<span style={{
                fontSize: "30px",
                color: "#0b1a74",
                textShadow: "2px 0 0 #ffffff, 0 2px 0 #ffffff, -2px 0 0 #ffffff, 0 -2px 0 #ffffff, 2px 2px #ffffff, -2px -2px 0 #ffffff, 2px -2px 0 #ffffff, -2px 2px 0 #ffffff"
            }}>Welcome to CUD IQ180 !</span>}
                  style={{
                      margin: 20,
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
                      backgroundColor: "rgba(230, 190, 255, 0.7)",
                      padding: 1,
                  }}>

                <Row>
                    {playerList &&
                        playerList.map((player, index) => (
                            <Col span={6}>
                                <Card key={index} style={gridStyle}>
                                    <div style={{
                                        display: "flex",
                                        alignContent: "flex-start",
                                        verticalAlign: "middle",
                                        height: ""
                                    }}>
                                        <UserOutlined style={{fontSize: '40px', marginRight: '5px',}}/>
                                        <Text style={{fontSize: "20px", overflow: "auto", maxWidth: "max-content"}}> {player}</Text>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                </Row>
            </Card>
            <audio id="background" loop autoPlay>
                <source src="/Mister Exposition.mp3" type="audio/mpeg"/>
            </audio>
        </>
    );

}

export default NotReady;