import React from 'react';

function Credits() {
    return (
        <div className="credits">
            <p>©2024 Department of Mathematics - Chulalongkorn University Demonstration Secondary School</p>
            <p>Developed by Kittipos CUD62 & Bhira CUD62</p>
        </div>
    );
}

export default Credits;