import React, {useState, useEffect} from 'react';
import {Progress} from 'antd';

function ProgressBar(props) {
    const {start, end} = props;
    const [percentage, setPercentage] = useState(0);
    const [secondsLeft, setSecondsLeft] = useState(0);

    useEffect(() => {
        const calculateTime = async () => {
            // const currentTime = await getServerTime();
            const currentTime = new Date().getTime();
            const totalTime = end - start;
            const elapsedTime = currentTime - start;

            const remainingSeconds = Math.max(0, Math.round((totalTime - elapsedTime) / 1000));
            const calculatedPercentage = Math.min(100, Math.max(0, 100 - (remainingSeconds / (totalTime / 1000) * 100)));

            setPercentage(calculatedPercentage);
            setSecondsLeft(remainingSeconds);
        };

        calculateTime();
        const intervalId = setInterval(calculateTime, 200);

        return () => clearInterval(intervalId);
    }, [start, end]);

    // Declare a color object for progress colors
    const colors = {
        normal: "#07d211",
        caution: "#faad14",
        warning: "#ff4d4f"
    };

    // Determine the color based on the percentage
    let color;
    if (percentage >= 75) {
        color = colors.warning; // Error: Assignment to constant variable
    } else if (percentage >= 50) {
        color = colors.caution; // Error: Assignment to constant variable
    } else {
        color = colors.normal; // Error: Assignment to constant variable
    }
    return (
        <div style={{alignItems: 'center'}}>
            <p style={{textAlign: 'left', margin: 20, marginLeft: 60, lineHeight: 0}}>
                Time left: {isNaN(secondsLeft) ? "" : `${secondsLeft} seconds`}
            </p>
            {/*eslint-disable-next-line*/}
            <Progress style={{marginLeft: 0, marginRight: 0,}} percent={100 - percentage} strokeColor={color}
                      size={['90%', 30]} showInfo={false}/>
        </div>
    );
}
//
// function getServerTime() {
//     const apiUrl = localStorage.getItem('apiUrl');
//     const request = {
//         method: "get_current_time_millisecond"
//     };
//
//     return fetch(apiUrl, {
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json',
//         },
//         body: JSON.stringify(request),
//     })
//         .then(response => response.json())
//         .then(data => {
//             if (data && data.current_time_millisecond) {
//                 return Number(data.current_time_millisecond);
//             } else {
//                 throw new Error('Invalid response from server');
//             }
//         })
//         .catch(error => {
//             console.error('Error fetching server time:', error);
//         });
// }

export default ProgressBar;