import React from "react";
import {ConfigProvider, Table} from "antd";

function ScoreBoard(props) {
    const {rawData} = props;
    const convertScoreboardData = (data) => {
        const tableData = [];
        if (data && data.scoreboard) {
            Object.entries(data.scoreboard).forEach(([playerName, scores]) => {
                const row = {key: playerName, playerName};
                let totalScore = 0; // Initialize total score for each player
                scores.forEach((score, index) => {
                    if (score === "0") {
                    row[`round${index + 1}`] = "";
                    } else {
                    row[`round${index + 1}`] = score;
                    }
                    totalScore += parseInt(score); // Sum up the score for total
                });
                row['total'] = totalScore;
                tableData.push(row);
            });
        } else {
            console.error('scoreboard data is undefined or null');
        }
        return tableData;
    };

    const scoreBoardData = convertScoreboardData(rawData);
    const sortedScoreBoardData = scoreBoardData.sort((a, b) => b.total - a.total);

    // Assign ranks based on total score
    let currentRank = 1;
    let previousScore = null;
    let playersSkippedDueToSameScore = 0;
    sortedScoreBoardData.forEach((player, index) => {
        if (previousScore !== player.total) {
            currentRank += playersSkippedDueToSameScore;
            playersSkippedDueToSameScore = 1;
        } else {
            playersSkippedDueToSameScore++;
        }
        player.rank = currentRank;
        previousScore = player.total;
    });

    // Modify the addMedalsToPlayerNames function to use rank
    const addMedalsToPlayerNames = (data) => {
        return data.map((row) => {
            let medalPath = '';
            switch (row.rank) {
                case 1:
                    medalPath = '/medal/1.png';
                    break;
                case 2:
                    medalPath = '/medal/2.png';
                    break;
                case 3:
                    medalPath = '/medal/3.png';
                    break;
                case 4:
                    medalPath = '/medal/4.png';
                    break;
                case 5:
                    medalPath = '/medal/5.png';
                    break;
                case 6:
                    medalPath = '/medal/6.png';
                    break;
                case 7:
                    medalPath = '/medal/7.png';
                    break;
                case 8:
                    medalPath = '/medal/8.png';
                    break;
                case 9:
                    medalPath = '/medal/9.png';
                    break;
                case 10:
                    medalPath = '/medal/10.png';
                    break;
                default:
                    medalPath = '';
            }
            if (medalPath) {
                row.playerName = `<img src="${medalPath}" alt="medal" style="width: 40px; vertical-align: middle; margin-right: 5px;">${row.playerName}`;
            }
            return row;
        });
    };

    const scoreBoardDataWithMedals = addMedalsToPlayerNames(sortedScoreBoardData);

    const generateColumns = (rawData) => {
        const roundKeys = rawData && rawData.scoreboard ? Object.values(rawData.scoreboard)[0] : [];
        const roundColumns = roundKeys.map((_, index) => ({
            title: `${index + 1}`,
            dataIndex: `round${index + 1}`,
            key: `round${index + 1}`,
            width: 80

        }));

        return [
            {
                title: 'Player Name',
                dataIndex: 'playerName',
                key: 'playerName',
                fixed: 'left',
                width: 300,
                render: (text) => <div dangerouslySetInnerHTML={{__html: text}}/>,
            },
            ...roundColumns,
            {
                title: 'Total',
                dataIndex: 'total',
                key: 'total',
                fixed: 'right',
                width: 200,
                overflowX: 'hidden',
            }
        ];
    };
    const columnsWithMedals = generateColumns(rawData);
    return (
        <ConfigProvider
        theme={{
            components: {
                Table: {
                    cellFontSizeMD: '24px',
                }
            },
        }}
        >
        <Table
            style={{marginInline: 40}}
            columns={columnsWithMedals}
            dataSource={scoreBoardDataWithMedals}
            pagination={{pageSize: 8 , position: ['bottomLeft']}}
            size={"middle"}
            scroll={{ x: 1500, y: 720 }}
        /></ConfigProvider>
    );
}

export default ScoreBoard;