import React, {useState} from 'react';
import {Button, ConfigProvider, Image,  Spin, Table, Tag} from 'antd';
import {CalculatorTwoTone, SearchOutlined} from "@ant-design/icons";

function AnswerBoard(props) {
    const {
        roundNumber,
        answerDetails,
        solutionCount,
        solutionImage,
        canSolved,
        sendMessage,
        answerInfo,
        setModalName,
    } = props;
    const [showSolution, setShowSolution] = useState(false);
    const columns = [
        {
            title: 'Player Name',
            dataIndex: 'playerName',
            key: 'playerName',
            render: (text) => <p style={{marginLeft: '10px'}}>{text}</p>,
        },
        {
            title: 'Answer Status',
            dataIndex: 'answerStatus',
            key: 'answerStatus',
            render: (text) => (
                <Tag color={getColorForStatus(text)}>
                    {text === null ? '' : getTextForStatus(text)}
                </Tag>
            ),
        },
        {
            title: 'Action',
            dataIndex: 'answerStatus',
            key: 'answerStatus',
            render: (text, record) => (
                <Button disabled={!answerInfo[record.playerName]} onClick={() => getPlayerAnswerImage(record.playerName)}>
                    View Answer
                </Button>
            ),
        },
    ];

    const getColorForStatus = (status) => {
        switch (status) {
            case 'CORRECT':
                return '#20ad52';
            case 'SIMILAR':
                return '#ffd300';
            case 'FOUL':
                return '#e03232';
            default:
                return '';
        }
    };

    const getTextForStatus = (status) => {
        switch (status) {
            case 'CORRECT':
                return '️✓ Correct';
            case 'SIMILAR':
                return '~ Similar';
            case 'FOUL':
                return '⨯ Foul';
            case 'checking':
                return '⧗ Checking';
            default:
                return '';
        }
    };

    const dataSource = answerDetails
        ? Object.keys(answerDetails).map((playerName) => ({
            key: playerName,
            playerName: playerName,
            answerStatus: answerDetails[playerName],
        }))
        : [];
    const dataSourceWithTime = dataSource.map((player) => ({
        ...player,
        submittedTime: answerInfo[player.playerName] || null,
    }));
    const sortedDataSource = dataSourceWithTime.sort((a, b) => {
        if (a.submittedTime === null) return 1;
        if (b.submittedTime === null) return -1;
        return a.submittedTime - b.submittedTime;
    });

    function getPlayerAnswerImage(playerName) {

        const payload = {
            method: 'get_player_answer_image',
            round_number: roundNumber,
            player_name: playerName,
        };
        console.log('payload', payload);

        // Send the message
        sendMessage(JSON.stringify(payload));
        setModalName(playerName);


    }
    console.log(canSolved,typeof canSolved)
    return (
        <div>
            <Spin indicator={<CalculatorTwoTone spin loop />} spinning={solutionImage === null || solutionCount === 0}>
                <div
                    className="info-box"
                    style={{
                        border: '1px solid black',
                        padding: '10px',
                        width: '100vh',
                        height: 'auto',
                        margin: '10px',
                        backgroundColor: 'white',
                        borderRadius: '20px', // Making the card have a radius
                    }}
                >
                    <p style={{
                        fontSize: 28,
                        padding: 0
                    }}>{canSolved!=="false" ? `There are ${solutionCount} possible solutions` : "There is no possible solution , the closest solution is"}</p>

                    {/*<Button icon={<SearchOutlined />} iconPosition={"start"} onClick={setShowSolution(true)}>*/}
                    {/*    Show  Solution*/}
                    {/*</Button>*/}

                        {/*<Button icon={<EyeOutlined />} onClick={setShowSolution(false)}>Hide</Button>*/}
                    { !showSolution && (
                    <Button icon={<SearchOutlined />} onClick={() => setShowSolution(!showSolution)}>Solution Image</Button>)
                    }
                    {showSolution && (
                    <Image
                        alt="Example Solution"
                        style={{
                            width: '100vh',
                            height: '100px',
                            objectFit: 'none',
                            objectPosition: 'center'
                        }}
                        src={solutionImage}
                        fallback={""}
                    ></Image>)}
                </div>
            </Spin>
            <div
                className={'info-box'}
                style={{
                    border: '1px solid black',
                    padding: '10px',
                    width: '100vh',
                    height: 'auto',
                    margin: '10px',
                    backgroundColor: 'white',
                    borderRadius: '20px', // Making the card have a radius
                }}>
                <ConfigProvider
                    theme={{
                        components: {
                            Table: {
                                cellPaddingBlock: 1,
                                margin: '20px',
                            },
                        },
                    }}
                >
                    <Table columns={columns} dataSource={sortedDataSource} pagination={{pageSize: 8}}/>
                </ConfigProvider>
            </div>
        </div>
    );
}

export default AnswerBoard;
